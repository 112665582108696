import React from 'react';
import { useParams } from 'react-router-dom';
import Content from './Content';

const ContentIndex = () => {
  const { pageSlug } = useParams<{ pageSlug: string }>();
  return <Content pageSlug={pageSlug} />;
};

export default ContentIndex;
