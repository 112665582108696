import React from 'react';
import './styles/index.scss';
import Block from '../../components/Home/Block';
import Translate from '../Translate/Translate';
import LocalizedLink from '../LocalizedLink';
import { scrollToTop } from '../../services/Util';

interface Props {
  titleKeyword?: string;
  buttonLink?: string;
  buttonKeyword?: string;
}

export default class ErrorBanner extends React.Component<Props> {
  scrollToTop() {
    scrollToTop();
  }

  render() {
    const { titleKeyword, buttonLink, buttonKeyword } = this.props;
    return (
      <div className="component-errorBanner">
        <div className="component-errorBanner__container">
          {titleKeyword === 'false' ? null : (
            <h3 className="component-errorBanner__title">
              <Translate
                component="errorBanner"
                keyword={titleKeyword ? titleKeyword : 'default'}
              />
            </h3>
          )}
          <LocalizedLink
            className="component-errorBanner__buttons__item"
            to={buttonLink ? buttonLink : '/'}
          >
            <Translate
              component="link"
              keyword={buttonKeyword ? buttonKeyword : 'back-home'}
            />
          </LocalizedLink>
          <Block type="top" />
        </div>
      </div>
    );
  }
}
